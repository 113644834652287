import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactLoading from 'react-loading';
import Countdown from 'react-countdown';
import { Tooltip } from 'react-tooltip'

// const url = "http://localhost:5005";
const url = "https://kpr-svr.herokuapp.com"

function App() {
  const [kept, setKept] = useState({});
  const [teams, setTeams] = useState({});
  const [loading, setLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [draftSet, setDraftSet] = useState(false);
  const [teamView, setTeamView] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState({});

  const INEL_KPR = ['Cooper Kupp']

  useEffect(() => {
    const getPlayerKeeperValue = ({ id, draft }) => {
      const drafted = draft.find(x => x.player_id === id)
      return drafted?.round ?? 6
    }
  
    const buildTeams = ({ teams, players, users, draft }) => {
      return teams.reduce((acc, curr) => {
        const p = curr.players.reduce((a, c) => {
          const n = players[c].position === 'DEF' ? `${players[c].first_name} ${players[c].last_name} D/ST` : players[c].full_name
          return {
            ...a, [n]: {
              keep: getPlayerKeeperValue({ draft, id: c, owner: curr.owner_id }),
              name: n,
              pos: players[c].position,
              team: players[c].team ?? 'FA'
            }
          }
        }, {})
        const usr = users.find(x => x.user_id === curr.owner_id)
        const teamName = usr.metadata?.team_name ?? usr.display_name
        return { ...acc, [teamName]: p }
      }, {})
    }

    const setData = async () => {
      const [players, teamRes, users, draftInfo, nextDraft] = await Promise.all([
        fetch("https://api.sleeper.app/v1/players/nfl").then(x => x.json()),
        fetch(`https://api.sleeper.app/v1/league/${process.env.REACT_APP_LAST_YEAR_LEAGUE_ID}/rosters`).then(x => x.json()),
        fetch(`https://api.sleeper.app/v1/league/${process.env.REACT_APP_LAST_YEAR_LEAGUE_ID}/users`).then(x => x.json()),
        fetch(`https://api.sleeper.app/v1/league/${process.env.REACT_APP_LAST_YEAR_LEAGUE_ID}/drafts`).then(x => x.json()),
        fetch(`https://api.sleeper.app/v1/league/${process.env.REACT_APP_THIS_YEAR_LEAGUE_ID}/drafts`).then(x => x.json()),
      ])

      setDraftSet(nextDraft[0].start_time)
  
      const draft = await fetch(`https://api.sleeper.app/v1/draft/${draftInfo[0].draft_id}/picks`).then(x => x.json())
      const teams = await buildTeams({ teams: teamRes, players, users, draft })

      setTeams(teams)
      setLoading(false)
    }

    setData()

    axios(`${url}/api/all`).then(res => {
      setKept(res.data.data.reduce((acc, curr) => {
        return {
          ...acc, [curr.user]: {
            keeper: curr.keeper,
            cost: curr.cost
          }
        }
      }, {}))
    })
  }, []);

  const setKeeper = (teamName, { name, keep: round, pos, team }, disallow) => {
    if(!disallow) {
      setSelectedPlayer({ owner: teamName, name, round, pos, team, google: `https://www.google.com/search?q=${name} fantasy football` })
      setTeamView(prev => !prev)
    }
  }

  const locked = () => {
    axios.post(`${url}/api/save?user=${selectedPlayer.owner}&keeper=${selectedPlayer.name.toLowerCase()}&cost=${selectedPlayer.round}`).then(_ => {
      const keepers = { ...kept };
      keepers[selectedPlayer.owner].keeper = selectedPlayer.name.toLowerCase();
      setKept(keepers)
      setTeamView(prev => !prev)
    })
  }

  const handleSetIsShown = (data, teamName, shown) => {
    if (kept && kept[teamName] && kept[teamName].keeper === data.name.toLowerCase()) {
      setIsShown(shown ? teamName : '');
    }
  }

  const cancel = () => setTeamView(prev => !prev)

  return (
    <div className={!teamView ? "App App-locked" : "App"}>
      {!teamView && <div onClick={cancel} className='App-backdrop'></div>}
      {draftSet ? <div className='countdown-section'>
        Draft Countdown <Countdown date={draftSet} />
      </div> : <div className='filler'></div>}
      <div className="App-wrap">
        {loading && <div className="center"><ReactLoading type="spin" color="#fff" height="100%" /></div>}
        {!loading && teams &&
          Object.keys(teams).map((teamName, index) => {
            return ['lcushing', 'Kristi Kreme'].includes(teamName) ? <></> : (
              <div className="team" key={index}>
                <h1>{teamName}</h1>
                <ul>
                  <li className="headings">
                    <span className="rounder rounder--title">Round</span>
                    <span className="player player--title">Player</span>
                  </li>
                  {teams[teamName] &&
                    Object.values(teams[teamName]).map((curr) => curr.pos === 'K' ? <></> : (
                      <li
                        key={curr.name}
                        className={`${kept && kept[teamName] && kept[teamName].keeper === curr.name.toLowerCase() ? 'highlight ' : ' '}${INEL_KPR.includes(curr.name) ? 'inelligible' : ''}`}
                        onMouseEnter={() => handleSetIsShown(curr, teamName, true)}
                        onMouseLeave={() => handleSetIsShown(curr, teamName, false)}
                      >
                        <span className="rounder">Rd #{curr.keep}</span>
                        {INEL_KPR.includes(curr.name) &&
                          <>
                            <Tooltip id={`my-tooltip-${index}`} />
                            <i data-tooltip-id={`my-tooltip-${index}`} data-tooltip-content="Player Inelligible To Be Kept" className="inelligibe-tag fas fa-info-circle"></i>
                          </>
                        }
                        <button onClick={() => setKeeper(teamName, curr, INEL_KPR.includes(curr.name))}>
                          {curr.name}
                          {curr.pos !== 'DEF' &&
                            <span className="designation">
                              <span className={`Pos${curr.pos}`}>{curr.pos}</span> -
                              {` ${curr.team}`}
                            </span>}
                        </button>
                        {isShown === teamName && kept && kept[teamName] && kept[teamName].keeper === curr.name.toLowerCase() &&
                          <span className="ttip animated animatedFadeInUp fadeInUp">
                            <span className="ttip-name">{curr.name}</span>
                            {` is kept by ${teamName} in round ${curr.keep}`}
                          </span>
                        }
                        {kept && kept[teamName] && kept[teamName].keeper === curr.name.toLowerCase() &&
                          <i className="fas fa-tag"></i>
                        }
                      </li>
                    ))}
                </ul>
              </div>
            );
          })}
      </div>
      {
        !teamView &&
        <div className="modal animated animatedFadeInUp fadeInUpModal">
          <div className="keeper">
            <h1>Team {selectedPlayer.owner}</h1>
            <h2>
              Keep <span className="playername">{selectedPlayer.name}</span> {selectedPlayer.pos !== 'D/ST' &&
                <span className="posWrap">
                  , <span className={`Pos${selectedPlayer.pos}`}>{selectedPlayer.pos}</span> -
                  <span>
                    {` ${selectedPlayer.team}`}
                  </span>
                </span>} (Round {selectedPlayer.round})?
            </h2>
            <footer className="modal-footer">
              <a className="googler" target="_blank" rel="noreferrer" href={selectedPlayer.google}>(click to google {selectedPlayer.name})</a>
              <div className="btn-group">
                <button className="cancel" onClick={cancel}>cancel</button>
                <button className="lockin" onClick={locked}>lock in as keeper</button>
              </div>
            </footer>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
